<template>
  <div class="repairit">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        </div>
      </div>

      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          sortable
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="Preview.WeekDiscountSwitch"
          align="center"
          label="周折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.WeekDiscountSwitch === 0 ? "关闭" :  "开启"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.MonthDiscountSwitch"
          align="center"
          label="月折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.MonthDiscountSwitch === 0 ? "关闭" :  "开启"}}
          </template>
        </el-table-column>  

           <el-table-column
          prop="Preview.ToolKitRecommendSwitch"
          align="center"
          label="仅推Toolkit开关">
          <template slot-scope="scope">
            {{ scope.row.properties.ToolKitRecommendSwitch === 0 ? "关闭" :  "开启"}}
          </template>
        </el-table-column>  
        <el-table-column width="100"
          prop="create_by"
          :formatter="formatterEmpty"
          align="center"
          label="创建人">
        </el-table-column> 
        <el-table-column width="100"
          prop="update_by"
          :formatter="formatterEmpty"
          align="center"
          label="更新人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          :formatter="formatterDateTime"
          align="center"
          label="更新时间">
        </el-table-column>

        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditProductCfg(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteProductCfg(scope.row.Pid)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>


    <!-- 添加对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="产品PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入用户PID"/>
        </el-form-item>
        <el-form-item label="周折扣开关: " prop="WeekDiscountSwitch">
          <el-radio-group v-model="addForm.WeekDiscountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>      
        <el-form-item label="月折扣开关: " prop="MonthDiscountSwitch">
          <el-radio-group v-model="addForm.MonthDiscountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item> 

              <el-form-item label="仅推toolkit开关: " prop="ToolKitRecommendSwitch">
          <el-radio-group v-model="addForm.ToolKitRecommendSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item> 
      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddProductCfg" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {getProductCfg, addProductCfg, delProductCfg} from "@/api/productcfg";
import UploadExcelComp from "@/components/common/UploadExcel";
import {formatDate} from "@/utils/date"

export default {
  name: "repairit",
  components: {
    UploadExcelComp
  },
  data() {
    return {
      form: {
        name: ''
      },
      title: "",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 产品配置列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加配置信息
      addForm:{
        newPid: null,  // 产品PID
        WeekDiscountSwitch: 0, // 周折扣开关
        MonthDiscountSwitch: 0, // 月折扣开关
        ToolKitRecommendSwitch:0 // 仅推toolkit开关
      },
      addFormRules: {
        newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
      }
    }
  },
  created() {
    this.loadProductCfgInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        newPid: null,  // 产品PID
        WeekDiscountSwitch: 0, // 周折扣开关
        MonthDiscountSwitch: 0, // 月折扣开关
         ToolKitRecommendSwitch:0 // 仅推toolkit开关
      }
    },
    loadProductCfgInfo(page = 1) {
      this.loading = true
      getProductCfg({
        "product_type": 1,
        page,
        "count": this.pageSize,
        "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
      }).then(res => {
        this.data = res.data
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadProductCfgInfo(page)
    },
    /**
     *  根据条件查询
     */
    onSearch() {
      this.loadProductCfgInfo()
    },
    /**
     *  新增产品配置信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加产品配置信息"
    },
    /**
     * 删除用户评价信息
     */
    onDeleteProductCfg(pid) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProductCfg(pid).then(res => {
          //  删除成功
          this.loadProductCfgInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改产品配置信息
     */
    onEditProductCfg(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.newPid = data.Pid
      this.addForm.WeekDiscountSwitch = data.properties.WeekDiscountSwitch
      this.addForm.MonthDiscountSwitch = data.properties.MonthDiscountSwitch
      this.addForm.ToolKitRecommendSwitch = data.properties.ToolKitRecommendSwitch
      this.title = "修改产品配置信息"
    },
    /**
     * 添加用户Nps信息
     */
    onAddProductCfg() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addProductCfg({
            "product_type": 1,
            "data": {
              "Pid": parseInt(this.addForm.newPid),
              "properties": {
                    "WeekDiscountSwitch": this.addForm.WeekDiscountSwitch,
                    "MonthDiscountSwitch": this.addForm.MonthDiscountSwitch,
                    "ToolKitRecommendSwitch": this.addForm.ToolKitRecommendSwitch
              }
            }

          }).then(res => {
            //  添加成功，刷新列表
            this.loadProductCfgInfo(this.page)
            this.dialogFormVisible = false
            this.loading = false
            this.$message({
              message: `恭喜您，${ this.operType ? '修改' : '添加'}成功`,
              type: 'success'
            });
          }).catch(err => {
            this.loading = false
            this.$message({
              message: `不好意思，${ this.operType ? '修改' : '添加'}失败了哟，请重试`,
              type: 'warning'
            });
          })
        }
      })
    },
    //为空转换
    formatterEmpty(row, column, v){
      if (v || (v === 0)) return v
      return "-"
    },
    //时间转换
    formatterDateTime(row, column, v){
      if (!v) return "-"
      if(String(v).length === 10) v = v * 1000
      let date = new Date(v)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }  
  }
}
</script>

<style scoped>

.list-table {
  margin-bottom: 10px;
}

.btn {
  display: flex;
}

.preview-file .el-input{
  width: 120px;
}
</style>
