export const country = ()=>{
    return [
        {value: "AR", en: "Argentina", label: "阿根廷"},  
        {value: "AD", en: "Andorra", label: "安道尔"},  
        {value: "AE", en: "United Arab Emirates", label: "阿联酋"},  
        {value: "AF", en: "Afghanistan", label: "阿富汗"},  
        {value: "AG", en: "Antigua & Barbuda", label: "安提瓜和巴布达"},  
        {value: "AI", en: "Anguilla", label: "安圭拉"},  
        {value: "AL", en: "Albania", label: "阿尔巴尼亚"},  
        {value: "AM", en: "Armenia", label: "亚美尼亚"},  
        {value: "AO", en: "Angola", label: "安哥拉"},  
        {value: "AQ", en: "Antarctica", label: "南极洲"},  
        {value: "AS", en: "American Samoa", label: "美属萨摩亚"},  
        {value: "AT", en: "Austria", label: "奥地利"},  
        {value: "AU", en: "Australia", label: "澳大利亚"},  
        {value: "AW", en: "Aruba", label: "阿鲁巴"},  
        {value: "AX", en: "Aland Island", label: "奥兰群岛"},  
        {value: "AZ", en: "Azerbaijan", label: "阿塞拜疆"},  
        {value: "BA", en: "Bosnia & Herzegovina", label: "波黑"},  
        {value: "BB", en: "Barbados", label: "巴巴多斯"},  
        {value: "BD", en: "Bangladesh", label: "孟加拉"},  
        {value: "BE", en: "Belgium", label: "比利时"},  
        {value: "BF", en: "Burkina", label: "布基纳法索"},  
        {value: "BG", en: "Bulgaria", label: "保加利亚"},  
        {value: "BH", en: "Bahrain", label: "巴林"},  
        {value: "BI", en: "Burundi", label: "布隆迪"},  
        {value: "BJ", en: "Benin", label: "贝宁"},  
        {value: "BL", en: "Saint Barthélemy", label: "圣巴泰勒米岛"},  
        {value: "BM", en: "Bermuda", label: "百慕大"},  
        {value: "BN", en: "Brunei", label: "文莱"},  
        {value: "BO", en: "Bolivia", label: "玻利维亚"},  
        {value: "BQ", en: "Caribbean Netherlands", label: "荷兰加勒比区"},  
        {value: "BR", en: "Brazil", label: "巴西"},  
        {value: "BS", en: "The Bahamas", label: "巴哈马"},  
        {value: "BT", en: "Bhutan", label: "不丹"},  
        {value: "BV", en: "Bouvet Island", label: "布韦岛"},  
        {value: "BW", en: "Botswana", label: "博茨瓦纳"},  
        {value: "BY", en: "Belarus", label: "白俄罗斯"},  
        {value: "BZ", en: "Belize", label: "伯利兹"},  
        {value: "CA", en: "Canada", label: "加拿大"},  
        {value: "CC", en: "Cocos (Keeling) Islands", label: "科科斯群岛"},  
        {value: "CD", en: "Democratic Republic of the Congo", label: "刚果（金）"},  
        {value: "CF", en: "Central African Republic", label: "中非"},  
        {value: "CG", en: "Republic of the Congo", label: "刚果（布）"},  
        {value: "CH", en: "Switzerland", label: "瑞士"},  
        {value: "CI", en: "Cote d'Ivoire", label: "科特迪瓦"},  
        {value: "CK", en: "Cook Islands", label: "库克群岛"},  
        {value: "CL", en: "Chile", label: "智利"},  
        {value: "CM", en: "Cameroon", label: "喀麦隆"},  
        {value: "CN", en: "China", label: "中国"},  
        {value: "CO", en: "Colombia", label: "哥伦比亚"},  
        {value: "CR", en: "Costa Rica", label: "哥斯达黎加"},  
        {value: "CU", en: "Cuba", label: "古巴"},  
        {value: "CV", en: "Cape Verde", label: "佛得角"},  
        {value: "CW", en: "Curacao", label: "库拉索"},  
        {value: "CX", en: "Christmas Island", label: "圣诞岛"},  
        {value: "CY", en: "Cyprus", label: "塞浦路斯"},  
        {value: "CZ", en: "Czech Republic", label: "捷克"},  
        {value: "DE", en: "Germany", label: "德国"},  
        {value: "DJ", en: "Djibouti", label: "吉布提"},  
        {value: "DK", en: "Denmark", label: "丹麦"},  
        {value: "DM", en: "Dominica", label: "多米尼克"},  
        {value: "DO", en: "Dominican Republic", label: "多米尼加"},  
        {value: "DZ", en: "Algeria", label: "阿尔及利亚"},  
        {value: "EC", en: "Ecuador", label: "厄瓜多尔"},  
        {value: "EE", en: "Estonia", label: "爱沙尼亚"},  
        {value: "EG", en: "Egypt", label: "埃及"},  
        {value: "EH", en: "Western Sahara", label: "西撒哈拉"},  
        {value: "ER", en: "Eritrea", label: "厄立特里亚"},  
        {value: "ES", en: "Spain", label: "西班牙"},  
        {value: "ET", en: "Ethiopia", label: "埃塞俄比亚"},  
        {value: "FI", en: "Finland", label: "芬兰"},  
        {value: "FJ", en: "Fiji", label: "斐济群岛"},  
        {value: "FK", en: "Falkland Islands", label: "马尔维纳斯群岛（福克兰）"},  
        {value: "FM", en: "Federated States of Micronesia", label: "密克罗尼西亚联邦"},  
        {value: "FO", en: "Faroe Islands", label: "法罗群岛"},  
        {value: "FR", en: "France", label: "法国 法国"},  
        {value: "GA", en: "Gabon", label: "加蓬"},  
        {value: "GB", en: "Great Britain (United Kingdom; England)", label: "英国"},  
        {value: "GD", en: "Grenada", label: "格林纳达"},  
        {value: "GE", en: "Georgia", label: "格鲁吉亚"},  
        {value: "GF", en: "French Guiana", label: "法属圭亚那"},  
        {value: "GG", en: "Guernsey", label: "根西岛"},  
        {value: "GH", en: "Ghana", label: "加纳"},  
        {value: "GI", en: "Gibraltar", label: "直布罗陀"},  
        {value: "GL", en: "Greenland", label: "格陵兰"},  
        {value: "GM", en: "Gambia", label: "冈比亚"},  
        {value: "GN", en: "Guinea", label: "几内亚"},  
        {value: "GP", en: "Guadeloupe", label: "瓜德罗普"},  
        {value: "GQ", en: "Equatorial Guinea", label: "赤道几内亚"},  
        {value: "GR", en: "Greece", label: "希腊"},  
        {value: "GS", en: "South Georgia and the South Sandwich Islands", label: "南乔治亚岛和南桑威奇群岛"},  
        {value: "GT", en: "Guatemala", label: "危地马拉"},  
        {value: "GU", en: "Guam", label: "关岛"},  
        {value: "GW", en: "Guinea-Bissau", label: "几内亚比绍"},  
        {value: "GY", en: "Guyana", label: "圭亚那"},
        {value: "HK", en: "Hong Kong", label: "香港"},  
        {value: "HM", en: "Heard Island and McDonald Islands", label: "赫德岛和麦克唐纳群岛"},  
        {value: "HN", en: "Honduras", label: "洪都拉斯"},  
        {value: "HR", en: "Croatia", label: "克罗地亚"},  
        {value: "HT", en: "Haiti", label: "海地"},  
        {value: "HU", en: "Hungary", label: "匈牙利"},  
        {value: "ID", en: "Indonesia", label: "印尼"},  
        {value: "IE", en: "Ireland", label: "爱尔兰"},  
        {value: "IL", en: "Israel", label: "以色列"},  
        {value: "IM", en: "Isle of Man", label: "马恩岛"},  
        {value: "IN", en: "India", label: "印度"},  
        {value: "IO", en: "British Indian Ocean Territory", label: "英属印度洋领地"},  
        {value: "IQ", en: "Iraq", label: "伊拉克"},  
        {value: "IR", en: "Iran", label: "伊朗"},  
        {value: "IS", en: "Iceland", label: "冰岛"},  
        {value: "IT", en: "Italy", label: "意大利"},  
        {value: "JE", en: "Jersey", label: "泽西岛"},  
        {value: "JM", en: "Jamaica", label: "牙买加"},  
        {value: "JO", en: "Jordan", label: "约旦"},  
        {value: "JP", en: "Japan", label: "日本"},  
        {value: "KE", en: "Kenya", label: "肯尼亚"},  
        {value: "KG", en: "Kyrgyzstan", label: "吉尔吉斯斯坦"},  
        {value: "KH", en: "Cambodia", label: "柬埔寨"},  
        {value: "KI", en: "Kiribati", label: "基里巴斯"},  
        {value: "KM", en: "The Comoros", label: "科摩罗"},  
        {value: "KN", en: "St. Kitts & Nevis", label: "圣基茨和尼维斯"},  
        {value: "KP", en: "North Korea", label: "朝鲜"},  
        {value: "KR", en: "South Korea", label: "韩国"},  
        {value: "KW", en: "Kuwait", label: "科威特"},  
        {value: "KY", en: "Cayman Islands", label: "开曼群岛"},  
        {value: "KZ", en: "Kazakhstan", label: "哈萨克斯坦"},  
        {value: "LA", en: "Laos", label: "老挝"},  
        {value: "LB", en: "Lebanon", label: "黎巴嫩"},  
        {value: "LC", en: "St. Lucia", label: "圣卢西亚"},  
        {value: "LI", en: "Liechtenstein", label: "列支敦士登"},  
        {value: "LK", en: "Sri Lanka", label: "斯里兰卡"},  
        {value: "LR", en: "Liberia", label: "利比里亚"},  
        {value: "LS", en: "Lesotho", label: "莱索托"},  
        {value: "LT", en: "Lithuania", label: "立陶宛"},  
        {value: "LU", en: "Luxembourg", label: "卢森堡"},  
        {value: "LV", en: "Latvia", label: "拉脱维亚"},  
        {value: "LY", en: "Libya", label: "利比亚"},  
        {value: "MA", en: "Morocco", label: "摩洛哥"},  
        {value: "MC", en: "Monaco", label: "摩纳哥"},  
        {value: "MD", en: "Moldova", label: "摩尔多瓦"},  
        {value: "ME", en: "Montenegro", label: "黑山"},  
        {value: "MF", en: "Saint Martin (France)", label: "法属圣马丁"},  
        {value: "MG", en: "Madagascar", label: "马达加斯加"},  
        {value: "MH", en: "Marshall islands", label: "马绍尔群岛"},  
        {value: "MK", en: "Republic of Macedonia (FYROM)", label: "马其顿"},  
        {value: "ML", en: "Mali", label: "马里"},  
        {value: "MM", en: "Myanmar (Burma)", label: "缅甸"},  
        {value: "MN", en: "Mongolia", label: "蒙古国"},  
        {value: "MO", en: "Macao", label: "澳门"},
        {value: "MP", en: "Northern Mariana Islands", label: "北马里亚纳群岛"},  
        {value: "MQ", en: "Martinique", label: "马提尼克"},  
        {value: "MR", en: "Mauritania", label: "毛里塔尼亚"},  
        {value: "MS", en: "Montserrat", label: "蒙塞拉特岛"},  
        {value: "MT", en: "Malta", label: "马耳他"},  
        {value: "MU", en: "Mauritius", label: "毛里求斯"},  
        {value: "MV", en: "Maldives", label: "马尔代夫"},  
        {value: "MW", en: "Malawi", label: "马拉维"},  
        {value: "MX", en: "Mexico", label: "墨西哥"},  
        {value: "MY", en: "Malaysia", label: "马来西亚"},  
        {value: "MZ", en: "Mozambique", label: "莫桑比克"},  
        {value: "NA", en: "Namibia", label: "纳米比亚"},  
        {value: "NC", en: "New Caledonia", label: "新喀里多尼亚"},  
        {value: "NE", en: "Niger", label: "尼日尔"},  
        {value: "NF", en: "Norfolk Island", label: "诺福克岛"},  
        {value: "NG", en: "Nigeria", label: "尼日利亚"},  
        {value: "NI", en: "Nicaragua", label: "尼加拉瓜"},  
        {value: "NL", en: "Netherlands", label: "荷兰"},  
        {value: "NO", en: "Norway", label: "挪威"},  
        {value: "NP", en: "Nepal", label: "尼泊尔"},  
        {value: "NR", en: "Nauru", label: "瑙鲁"},  
        {value: "NU", en: "Niue", label: "纽埃"},  
        {value: "NZ", en: "New Zealand", label: "新西兰"},  
        {value: "OM", en: "Oman", label: "阿曼"},  
        {value: "PA", en: "Panama", label: "巴拿马"},  
        {value: "PE", en: "Peru", label: "秘鲁"},  
        {value: "PF", en: "French polynesia", label: "法属波利尼西亚"},  
        {value: "PG", en: "Papua New Guinea", label: "巴布亚新几内亚"},  
        {value: "PH", en: "The Philippines", label: "菲律宾"},  
        {value: "PK", en: "Pakistan", label: "巴基斯坦"},  
        {value: "PL", en: "Poland", label: "波兰"},  
        {value: "PM", en: "Saint-Pierre and Miquelon", label: "圣皮埃尔和密克隆"},  
        {value: "PN", en: "Pitcairn Islands", label: "皮特凯恩群岛"},  
        {value: "PR", en: "Puerto Rico", label: "波多黎各"},  
        {value: "PS", en: "Palestinian territories", label: "巴勒斯坦"},  
        {value: "PT", en: "Portugal", label: "葡萄牙"},  
        {value: "PW", en: "Palau", label: "帕劳"},  
        {value: "PY", en: "Paraguay", label: "巴拉圭"},  
        {value: "QA", en: "Qatar", label: "卡塔尔"},  
        {value: "RE", en: "Réunion", label: "留尼汪"},  
        {value: "RO", en: "Romania", label: "罗马尼亚"},  
        {value: "RS", en: "Serbia", label: "塞尔维亚"},  
        {value: "RU", en: "Russian Federation", label: "俄罗斯"},  
        {value: "RW", en: "Rwanda", label: "卢旺达"},  
        {value: "SA", en: "Saudi Arabia", label: "沙特阿拉伯"},  
        {value: "SB", en: "Solomon Islands", label: "所罗门群岛"},  
        {value: "SC", en: "Seychelles", label: "塞舌尔"},  
        {value: "SD", en: "Sudan", label: "苏丹"},  
        {value: "SE", en: "Sweden", label: "瑞典"},  
        {value: "SG", en: "Singapore", label: "新加坡"},  
        {value: "SH", en: "St. Helena & Dependencies", label: "圣赫勒拿"},  
        {value: "SI", en: "Slovenia", label: "斯洛文尼亚"},  
        {value: "SJ", en: "Svalbard and Jan Mayen", label: "斯瓦尔巴群岛和扬马延岛"},  
        {value: "SK", en: "Slovakia", label: "斯洛伐克"},  
        {value: "SL", en: "Sierra Leone", label: "塞拉利昂"},  
        {value: "SM", en: "San Marino", label: "圣马力诺"},  
        {value: "SN", en: "Senegal", label: "塞内加尔"},  
        {value: "SO", en: "Somalia", label: "索马里"},  
        {value: "SR", en: "Suriname", label: "苏里南"},  
        {value: "SS", en: "South Sudan", label: "南苏丹"},  
        {value: "ST", en: "Sao Tome & Principe", label: "圣多美和普林西比"},  
        {value: "SV", en: "El Salvador", label: "萨尔瓦多"},  
        {value: "SX", en: "Sint Maarten", label: "荷属圣马丁"},  
        {value: "SY", en: "Syria", label: "叙利亚"},  
        {value: "SZ", en: "Swaziland", label: "斯威士兰"},  
        {value: "TC", en: "Turks & Caicos Islands", label: "特克斯和凯科斯群岛"},  
        {value: "TD", en: "Chad", label: "乍得"},  
        {value: "TF", en: "French Southern Territories", label: "法属南部领地"},  
        {value: "TG", en: "Togo", label: "多哥"},  
        {value: "TH", en: "Thailand", label: "泰国"},  
        {value: "TJ", en: "Tajikistan", label: "塔吉克斯坦"},  
        {value: "TK", en: "Tokelau", label: "托克劳"},  
        {value: "TL", en: "Timor-Leste (East Timor)", label: "东帝汶"},  
        {value: "TM", en: "Turkmenistan", label: "土库曼斯坦"},  
        {value: "TN", en: "Tunisia", label: "突尼斯"},  
        {value: "TO", en: "Tonga", label: "汤加"},  
        {value: "TR", en: "Turkey", label: "土耳其"},  
        {value: "TT", en: "Trinidad & Tobago", label: "特立尼达和多巴哥"},  
        {value: "TV", en: "Tuvalu", label: "图瓦卢"},  
        {value: "TW", en: "Taiwan", label: "台湾"},  
        {value: "TZ", en: "Tanzania", label: "坦桑尼亚"},  
        {value: "UA", en: "Ukraine", label: "乌克兰"},  
        {value: "UG", en: "Uganda", label: "乌干达"},  
        {value: "UM", en: "United States Minor Outlying Islands", label: "美国本土外小岛屿"},  
        {value: "US", en: "United States of America (USA)", label: "美国"},
        {value: "UY", en: "Uruguay", label: "乌拉圭"},  
        {value: "UZ", en: "Uzbekistan", label: "乌兹别克斯坦"},  
        {value: "VA", en: "Vatican City (The Holy See)", label: "梵蒂冈"},  
        {value: "VC", en: "St. Vincent & the Grenadines", label: "圣文森特和格林纳丁斯"},  
        {value: "VE", en: "Venezuela", label: "委内瑞拉"},  
        {value: "VG", en: "British Virgin Islands", label: "英属维尔京群岛"},  
        {value: "VI", en: "United States Virgin Islands", label: "美属维尔京群岛"},  
        {value: "VN", en: "Vietnam", label: "越南"},  
        {value: "VU", en: "Vanuatu", label: "瓦努阿图"},  
        {value: "WF", en: "Wallis and Futuna", label: "瓦利斯和富图纳"},  
        {value: "WS", en: "Samoa", label: "萨摩亚"},  
        {value: "YE", en: "Yemen", label: "也门"},  
        {value: "YT", en: "Mayotte", label: "马约特"},  
        {value: "ZA", en: "South Africa", label: "南非"},  
        {value: "ZM", en: "Zambia", label: "赞比亚"},  
        {value: "ZW", en: "Zimbabwe", label: "津巴布韦"}  
    ]
}