/**这个是接口返回的字段 以后要加就加在这 */
export const  type = [
              "LeftAds",
              "RightAds",
              "ExitAds",
              "RecommandAds",
              "DiscountWinBackAds",
              "StickerAds",
              "UserSurveyAds",
              "UninstallWinBackAds",
              "PayGuideRetainAds",

            ];

            /**这个是前端层面数据展示 与上面字段对应的 这一块加了新内容记得在addForm也同步加一下 */
export const  typeKey = ["left", 
  "right", 
  "exit", 
  "recommand", 
  "discount",
  "sticker",
  'survey',
  'uninstall',
  'pay'];