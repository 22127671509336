<template>
  <div id="webConfigApp">
    default webconfigapp.....
  </div>
</template>

<script>
export default {
  name: 'SubAppContainer',
}
</script>